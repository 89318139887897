<template>
  <o-loading :full-page="true" :active="isLoading" :can-cancel="false"></o-loading>
  <div class="list-view">
    <mf-table
      ref="BOItemTable"
      :tableProps="tableProps"
      :hideGutter="true"
      :loadData="loadData"
      :screenTable="true"
      tableName="BOItemTable"
      @cell-clicked="onCellClicked"
    >
    <template v-slot:itemPercComplete="psData">
      {{calculatePercentCompleteItemLevel(psData.rowData)}}
    </template>
    <template v-slot:financialId="psData">
      {{psData.rowData.financialId}}
    </template>
    <template v-slot:cardName="psData">
      <span class="text-overflow">{{psData.rowData.cardName}}</span>
    </template>
    <template v-slot:locationName="psData">
      {{$_.get(psData, 'rowData.manager.location.name', '')}}
    </template>
    <template v-slot:lastModified="psData">
      <span class="is-pulled-right">
        {{$filters.usaDate(psData.rowData.lastModified.at)}}
      </span>
    </template>
    <template v-slot:project="psData">
      {{psData.rowData.project.name }}
    </template>
    <template v-slot:catId="psData">
      {{psData.rowData.catId }}
    </template>
    <template v-slot:runPercComplete="psData">
      {{calculatePercentCompleteRunLevel(psData.rowData)}}
    </template>
    </mf-table>
    <transition name="slide">
      <notes-icon-slider
        v-if="isSlideNotesActive"
        :isActive="isSlideNotesActive"
        :rowData="selectedOrder"
        @close="closeNotesSlider"
        @update:note="updateNotes($event)"
      >
      </notes-icon-slider>
    </transition>
  </div>
</template>

<script>
import {
  defineComponent, reactive, toRefs, ref, onBeforeUnmount, inject,
} from 'vue';
import _ from 'lodash';
import { useStore } from 'vuex';
import SupplyChain from '@/models/SupplyChain';
import MfTable from '@/components/table-fields/MfTable.vue';
import { BaseOrder } from '@/models/BaseOrder';
import { useRouter } from 'vue-router';
import msItemsCols from '@/components/table-cols/MaterialStatusItemViewCols';
import psItemsCols from '@/components/table-cols/PSItemViewCols';
import NotesIconSlider from '@/components/modals/NotesIconSlider.vue';

export default defineComponent({
  name: 'Production-Status-item-view',
  components: {
    MfTable,
    'notes-icon-slider': NotesIconSlider,
  },
  props: ['stage', 'type', 'excelExport'],
  setup(props) {
    const BOItemTable = ref(null);
    const store = useStore();
    const router = useRouter();
    const emitter = inject('emitter');
    const state = reactive({
      viewModelOpen: false,
      tableProps: store.state.activeScreen === 'material-status-item-view' ? msItemsCols : psItemsCols,
      isLoading: false,
      isSlideNotesActive: false,
      selectedOrder: {},
    });

    const loadData = ({ limit, page }) => {
      state.isLoading = true;
      const queryParams = {
        projectId: store.getters.selectedIdsForKey('filteredProjects'),
        showAllCompanyOrders: store.state.queryParams.showAllCompanyOrders,
        submittals: store.getters.selectedSubmittals,
        location: store.getters.selectedIdsForKey('location', false),
        company: store.getters.selectedIdsForKey('companies', false),
        owner: store.getters.selectedOwner,
        status: store.getters.selectedValuesForKey('status', false),
        stage: props.type === 'productionStatus' ? store.getters.selectedValuesForKey('stage', false) : store.getters.selectedValuesForKey('stage', false),
        ...store.getters.selectedDatesForKeys([
          'deliverStartDate',
          'deliverEndDate',
          'coordStartDate',
          'coordEndDate',
          'detailByStartDate',
          'detailByEndDate',
          'manufactureByStartDate',
          'manufactureByEndDate',
          'qaByStartDate',
          'qaByEndDate',
          'modifiedStartDate',
          'modifiedEndDate',
        ]),
        module: props.type === 'productionStatus' ? ['Prefabs', 'ProductionOrder'] : ['Materials', 'Sourcing'],
        search: store.state.queryParams.searchText,
        showPrivate: true,
        itemView: true,
        limit,
        page,
      };
      if (props.type === 'productionStatus') {
        queryParams.screen = 'productionStatus';
      }
      state.isLoading = false;
      return SupplyChain.itemView(queryParams);
    };

    const calculatePercentCompleteRunLevel = (row) => _.get(row, 'manager.stats.itemPerc', 0);
    const calculatePercentCompleteItemLevel = (row) => _.get(row, 'manager.stats.runsPerc', 0);
    const onCellClicked = async (event) => {
      if (event.type === 'openCard') {
        let moduleName = '';
        let stage = '';
        let orderStage = '';
        const card = event.data;
        const projectId = _.get(card.project, '_id', '');
        const { cardId } = card;
        if (card instanceof BaseOrder) {
          if (card.isPrefab()) moduleName = 'prefab';
          else if (card.isPO()) moduleName = 'order';
          else if (card.isPM()) moduleName = 'manager';
          else if (card.isMM() || card.__t === 'Sourcing') moduleName = 'material';
          if (['in-transit', 'not-started', 'mixed-shipping'].includes(card.stage)) orderStage = 'delivery';
          else if (['released-to-inventory', 'in-storage', 'fulfilled'].includes(card.stage)) orderStage = 'complete';
          else orderStage = card.stage;
          stage = props.stage || (card.stage === 'mixed' ? card.getCardStage() : orderStage);
        }
        router.push({
          name: `${moduleName}-edit`,
          params: { projectId, cardId, stage },
        });
      } else if (event.type === 'excelExport') {
        props.excelExport();
      }
    };

    const closeNotesSlider = (() => {
      state.isSlideNotesActive = false;
      state.selectedOrder = {};
    });

    // trigger notes slider on emit
    emitter.on('toggle:notesSlider', (payload) => { // *Listen* for event
      state.selectedOrder = payload.data;
      state.isItem = payload.isItem;
      state.isSlideNotesActive = payload.isActive;
    });

    onBeforeUnmount(() => {
      // removing eventBus listener
      emitter.off('toggle:notesSlider');
    });

    return {
      ...toRefs(state),
      BOItemTable,
      loadData,
      onCellClicked,
      calculatePercentCompleteRunLevel,
      calculatePercentCompleteItemLevel,
      closeNotesSlider,
    };
  },
});
</script>
