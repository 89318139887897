<template>
  <div :id="fId">
  </div>
</template>

<script>

import $ from 'jquery';
import fileUpload from '@/models/Upload';
import { debounce, isString, isEmpty } from 'lodash';
import { toRefs, reactive, onMounted } from 'vue';
import { useStore } from 'vuex';
import { useToast } from 'vue-toastification';

window.$ = $;
window.jQuery = $;

require('jquery-ui-sortable');
require('mf-form-builder');
require('mf-form-builder/dist/form-render.min');

const s3URL = 'https://s3-us-west-2.amazonaws.com/message-store-prod';

export default {
  name: 'FormBuilder',
  props: ['templateForm', 'editForm', 'showForm'],
  setup({ templateForm, editForm, showForm }, { emit }) {
    const store = useStore();
    const toast = useToast();
    const data = reactive({
      fId: `form-${Math.floor(Math.random() * 100)}`,
      user: null,
      form: null,
      dom: null,
      renderInstance: null,
      opts: {
        dataAttrs: ['_id', 'archived'],
        scrollToFieldOnAdd: true,
        disabledAttrs: [
          'name',
          'inline',
          'access',
          'className',
        ],
        fields: [
          {
            type: 'checkbox-group',
            label: 'Checkbox',
            iconClassName: 'far fa-check-square',
          },
          {
            type: 'paragraph',
            label: 'Date/Time Saved',
            className: 'time-saved',
            iconClassName: 'far fa-clock',
            disableEditOnAdd: true,
            disabledFieldButtons: [
              'edit',
              'copy',
            ],
            attrs: [
              {
                customtype: 'date-time-saved',
              },
            ],
          },
          {
            type: 'select',
            label: 'Dropdown List',
            iconClassName: 'far fa-caret-square-down',
          },
          {
            type: 'file',
            label: 'File/Picture Upload',
            iconClassName: 'fas fa-upload',
            disabledAttrs: [
              'placeholder',
              'subtype',
            ],
          },
          {
            type: 'header',
            label: 'Header',
            iconClassName: 'fas fa-heading',
          },
          {
            type: 'number',
            label: 'Number',
            iconClassName: 'fas fa-hashtag',
            disabledAttrs: [
              'value',
            ],
          },
          {
            type: 'radio-group',
            label: 'Radio Buttons',
            iconClassName: 'icon-radio',
          },
          {
            type: 'checkbox-group',
            label: 'Signature',
            iconClassName: 'fas fa-signature',
            className: 'signature',
            values: [
              {
                label: 'Check to sign',
                value: 'signed',
              },
            ],
            disabledAttrs: [
              'toggle',
              'access',
              'inline',
              'other',
            ],
            hiddenAttrs: [
              'className',
              'options',
            ],
          },
          {
            type: 'paragraph',
            label: 'Text and Image',
            iconClassName: 'fas fa-text',
            disabledAttrs: [
              'subtype',
            ],
          },
          {
            type: 'text',
            label: 'Text Input (Single-line)',
            iconClassName: 'icon-signle',
            disabledAttrs: [
              'value',
              'subtype',
            ],
          },
          {
            type: 'textarea',
            label: 'Text Input (Multi-line)',
            iconClassName: 'icon-multi-line',
            disabledAttrs: [
              'value',
              'subtype',
            ],
          },
        ],
        disableFields: [
          'select',
          'checkbox-group',
          'file',
          'header',
          'number',
          'paragraph',
          'autocomplete',
          'button',
          'hidden',
          'text',
          'textarea',
          'radio-group',
          'date',
        ],
        customOrder: true,
        editOnAdd: true,
        disabledActionButtons: [
          'data',
          'clear',
          'save',
        ],
      },
    });

    const parse = (dataToParse) => (isString(dataToParse) ? JSON.parse(dataToParse) : dataToParse);

    const editWarning = debounce(() => {
      toast.warning('Cannot edit the field once the form is saved. Please remove and add a new field instead');
    }, 500);

    const buildForm = async (formData = []) => {
      data.form = $(data.dom).formBuilder(data.opts);
      if (!isEmpty(formData)) {
        const form = await data.form.promise;
        form.actions.clearFields();
        form.actions.setData(formData);
      }
    };

    const uploadFile = async (file) => {
      const maxFileSize = 20 * 1000 * 1000;
      if (file.size > maxFileSize) {
        toast.error(`File must be ${maxFileSize / (1000 * 1000)} megabytes or less`);
        return null;
      }
      try {
        const { company } = data.user;
        const formData = new FormData();
        formData.append('fileName', file);
        const res = await fileUpload.uploadFormFile(company, formData);
        if (res && res.result && res.result === 'success') {
          file.imageUrl = `${s3URL}/companies/${company}/${res.imageUrl.fileGuid}`;
          return file;
        }
      } catch (e) {
        console.log(e);
      }
      return null;
    };

    const renderFrom = async (formData = []) => {
      formData = parse(formData);
      // get userInfo
      let signatureUrl;
      if (data.user.signatureUrl && data.user.signatureUrl.fileGuid) {
        signatureUrl = `${s3URL}/users/${data.user.id}/${data.user.signatureUrl.fileGuid}`;
      }
      // render options
      const renderOpts = {
        formData,
        userInfo: {
          name: data.user.fullName || '',
          signatureUrl,
          accountUrl: '/#/account/general-settings',
        },
        fileUploadApi: uploadFile,
        render: true,
      };
      data.renderInstance = $(data.dom).formRender(renderOpts);
      emit('loaded');
    };

    const getFormData = () => data.form.actions.getData();

    const edit = () => {
      const formData = getFormData();
      $(data.dom).empty();
      buildForm(formData);
    };

    const preview = () => {
      renderFrom(getFormData());
    };

    const clear = () => {
      data.form.actions.clearFields();
    };

    const save = () => {
      const formData = data.form.actions.save();
      return parse(formData);
    };

    const update = () => {
      const formData = data.renderInstance.update();
      return parse(formData);
    };

    onMounted(async () => {
      let projectId = '';
      let projectName = '';
      await store.getters.userPromise;
      data.user = store.state.userData;
      if (!isEmpty(templateForm.project)) {
        projectId = templateForm.project._id;
        projectName = templateForm.project.name;
      }
      if (templateForm._id && !isEmpty(data.user)
        && process.env.NODE_ENV === 'production') {
        // eslint-disable-next-line no-undef
        // pendo.track('Form Open Tracked', {
        //   userId: data.user._id,
        //   formName: templateForm.name,
        //   formId: templateForm._id,
        //   formUrl: templateForm.url,
        //   compiedFrom: templateForm.compiedFrom,
        //   projectId,
        //   projectName,
        // });
      }
      data.dom = $(document.getElementById(data.fId));
      if (editForm) {
        buildForm(templateForm.formData);
      } else {
        renderFrom(templateForm.formData, showForm);
      }
    });

    return {
      ...toRefs(data),
      edit,
      preview,
      clear,
      save,
      update,
      editWarning,
    };
  },
};

</script>

<style scoped>
</style>
